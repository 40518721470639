import "./style.scss"

const Index = ({ setSelectedProtein, selectedProtein, proteinFunctions }) => {
    return (
        <>
            <div className="header">
                <h2>Anti-Human {selectedProtein}</h2>
                <div className="icons-container"
                    onClick={() => {
                        setSelectedProtein(null)
                    }}
                >
                    <i className='fal fa-times'></i>
                </div>
            </div>
            <div className="mobile-overflow watermark">
          <p className="function-text" dangerouslySetInnerHTML={{ __html: proteinFunctions.find((item) => item.name === selectedProtein)?.function || '-' }}  />
          <div className="references">
            <h4>References:</h4>
            <div className="references-list">
              {proteinFunctions?.find((item) => item.name === selectedProtein)?.reference.filter((item) => item !== "").map((item, index) => {
                return <a href={item.includes("https") ? item : `https://${item}`} target="_blank" key={index}>[{index + 1}]</a>
              })}
            </div>
          </div>
        </div>
        </>
    )
}

export default Index