import React, { useRef, useState, useEffect, useContext } from 'react'
import Layout from "../../components/layout"
import { Header, Content, WrapperSmallGap, GlassCard } from "./style"
import Input from "../../components/Input"
import Pagination from "../../components/pagination"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import MethodologyModal from "../../components/modals/MethodologyModal"
import Coa from "../../components/coA"
import ItemPreviewDrawer from "./ItemPreviewDrawer"
import { getGalleryApi, getProteinFiltersApi } from '../../api'
import { AuthContext } from '../../context/auth'
import { useDebounce } from 'use-debounce'
import { Checkbox } from '../../components/Checkbox'
import { proteinsList as allProteinList, resolutionsFilters, dyeFilters, secondaryAntibodiesFilters } from '../../utils/proteinName'
import { Footer } from '../../components/Footer'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import { isVideoFile, getMp4Url, isGif } from '../../utils/videoHelper'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Index = () => {
    const dropdownRef = useRef(null)
    const coARef = useRef(null)
    const counter = useRef(0);

    // const { setIsLoading, isLoading } = useContext(AuthContext);
    const [openMethodologyModal, setOpenMethodologyModal] = useState(false)
    const [images, setImages] = useState({})
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState('')
    const [proteinSearch, setProteinSearch] = useState('')
    const [proteinsList, setProteinsList] = useState([])
    const [tempProteinsList, setTempProteinsList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [modalName, setModalName] = useState(null)
    const [openFilter, setOpenFilter] = useState(false)
    const [filters, setFilters] = useState({
        proteinTarget: {
            dropDown: true,
            selectedOptions: [],
        },
        conjugateType: {
            dropDown: true,
            selectedOptions: [],
        },
        dye: {
            dropDown: true,
            selectedOptions: [],
        },
        resolution: {
            dropDown: true,
            selectedOptions: [],
        },
        secondaryAntibodies: {
            dropDown: true,
            selectedOptions: [],
        }
    })
    const [tempFilters, setTempFilters] = useState({
        proteinTarget: {
            dropDown: true,
            selectedOptions: [],
        },
        conjugateType: {
            dropDown: true,
            selectedOptions: [],
        },
        dye: {
            dropDown: false,
            selectedOptions: [],
        },
        resolution: {
            dropDown: false,
            selectedOptions: [],
        },
        secondaryAntibodies: {
            dropDown: true,
            selectedOptions: '',
        }
    })
    const [filterApplied, setFilterApplied] = useState(false)
    const [debouncedSearch] = useDebounce(search, 500);
    const [previewProduct, setPreviewProduct] = useState(null)
    const [openItemPreviewModal, setOpenItemPreviewModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [productType, setProductType] = useState("alexafluor")


    useEffect(() => {
        fetchProteinFilters()
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Handle outside click
                setOpenFilter(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);


    const fetchGallery = () => {
        setIsLoading(true)
        const { proteinTarget, conjugateType, dye, resolution, secondaryAntibodies } = filters
        const selectedProteins = proteinTarget.selectedOptions?.map(item => item).join(",")
        // const selectedConjugates = conjugateType.selectedOptions?.map(item => item === 'Primary Conjugate' ? 'PA-MM,PA-MR,PA-RR,PA-RP,PA-RTM,DC' : 'SA').join(",")
        const containsControl = dye.selectedOptions?.includes('Control')
        const selectedDyes = dye.selectedOptions?.filter(item => item !== 'Control').map(item => item).join(",")
        const selectedResolutions = containsControl ? [...resolution.selectedOptions, 'Control'].join(",") : resolution.selectedOptions?.map(item => item).join(",")
        const selectedHost = extractAndCombinePayload(secondaryAntibodies?.selectedOptions)?.host
        const selectedSpecies = extractAndCombinePayload(secondaryAntibodies?.selectedOptions)?.species_reactivity
        const selectedChain = extractAndCombinePayload(secondaryAntibodies?.selectedOptions)?.chain_specification

        const payload = {
            subtitle: selectedProteins,
            type: productType === 'alexafluor' ? 'SA' : productType === 'primary' ? 'PA-MM,PA-MR,PA-RR,PA-RP,PA-RTM' : productType === 'primary_conjugate' ? 'DC' : "",
            dyes: selectedDyes,
            application: productType !== 'western' ? selectedResolutions : 'Western Blotting',
            host: selectedHost,
            species_reactivity: selectedSpecies,
            chain_specification: selectedChain
        };

        const params = Object.keys(payload).length > 0
            ? '&' + Object.entries(payload)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')
            : '';

        getGalleryApi(30, currentPage + 1, debouncedSearch, params).then(res => {
            if (res && res.data && res.data) {
                setImages(res?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 2500)
            } else {
                setImages({})
                setIsLoading(false)
            }
        })
    }

    const fetchProteinFilters = () => {
        setIsLoading(true)
        getProteinFiltersApi().then(res => {
            if (res && res?.data && res?.data?.proteins_available) {
                setProteinsList(res?.data?.proteins_available)
                setIsLoading(false)
            } else {
                setProteinsList(allProteinList)
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
            setProteinsList(allProteinList)
        })
    }


    useEffect(() => {
        fetchGallery()
        document.getElementById('gallery-grid').scrollIntoView({ block: 'center', behavior: 'smooth' });
        counter.current = 0;
    }, [currentPage, debouncedSearch, filters, productType])


    useEffect(() => {
        if (proteinSearch) {
            const filteredOptions = proteinsList.filter((option) => {
                return option.toLowerCase().includes(proteinSearch.toLowerCase())
            })
            setTempProteinsList(filteredOptions)
        } else {
            setTempProteinsList(proteinsList)
        }

    }, [proteinsList, proteinSearch])

    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= images?.products?.length - 1) {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (openFilter || openMethodologyModal) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [openFilter, openMethodologyModal])


    useEffect(() => {
        setFilterApplied(Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0) > 0 ? true : false)
    }, [filters])

    const TotalSelectedFilters = (filters) => {
        return Object.values(filters).reduce((total, field) => {
            if (Array.isArray(field.selectedOptions)) {
                return total + field.selectedOptions.length;
            } else if (typeof field.selectedOptions === 'string' && field.selectedOptions.trim()) {
                return total + 1;
            }
            return total;
        }, 0);
    }


    const extractAndCombinePayload = (data) => {
        let hosts = new Set();
        let speciesReactivities = new Set();
        let chainSpecifications = new Set();
        if (data?.length) {
            data?.forEach((item) => {
                const parts = item.split('&');

                parts.forEach((part) => {
                    const [key, value] = part.split('=');

                    if (key === 'species_reactivity') {
                        speciesReactivities.add(value);
                    } else if (key === 'chain_specification') {
                        chainSpecifications.add(value);
                    } else if (key === 'host') {
                        hosts.add(value);
                    }
                });
            });
        }
      
        const hostsArray = Array.from(hosts);
        const speciesReactivitiesArray = Array.from(speciesReactivities);
        const chainSpecificationsArray = Array.from(chainSpecifications);
      
        const PayloadData = {
          host: hostsArray.join(', '),
          species_reactivity: speciesReactivitiesArray.join(', '),
          chain_specification: chainSpecificationsArray.join(', ')
        };
      
        return PayloadData;
      
    }



    return (
        <Layout meta={{title: 'Gallery'}}>
            <Header>
            <WrapperSmallGap flexDirection="row" width={'50%'} justifyContent={"start"} alignItems={"start"} marginBottom={'40px'} marginBottomMobile={'0'}>
               <GlassCard>
                <h3>Identifyn’s™ Product Gallery Filter Allows Fine Tuning Your Applicaiton Across Resolutions Ranging From Widefield to Super Resolution and Single Molecule Applications</h3>
                <h3>No More Troubleshooting Identifyn™ Provides:</h3>
                <WrapperSmallGap flexDirection={'row'} gap={'5px'}>
                    <img src={toAbsoluteUrl("/media/antibody.png")} alt="antibody" style={{ width:'24px'}} />
                    <h3>Sample Preparation Methodology</h3>
                </WrapperSmallGap>
                <WrapperSmallGap flexDirection={'row'} gap={'5px'}>
                    <img src={toAbsoluteUrl("/media/antibody.png")} alt="antibody" style={{ width:'24px'}}  />
                    <h3>Optical Instrumentation and Settings Used in Image      Acquisition</h3>
                </WrapperSmallGap>
                <WrapperSmallGap flexDirection={'row'} gap={'5px'}>
                    <img src={toAbsoluteUrl("/media/antibody.png")} alt="antibody" style={{ width:'24px'}}  />
                    <h3>Post Processing Details for Structured Illumination, Airyscan, and STORM.</h3>
                </WrapperSmallGap>
               </GlassCard>
               <div></div>
            </WrapperSmallGap>
            <WrapperSmallGap flexDirection="row" justifyContent="center" alignItems="center" textAlign={'center'} width={'90%'} marginAuto={'auto'}>
                <GlassCard textAlign={'center'} padding={'32px'}>
                    <h3>Primary Antibodies</h3>
                </GlassCard>
                <GlassCard textAlign={'center'} padding={'16px 32px 16px 32px'}>
                    <h3>Identifyn™ SRM Alexa Fluor™ Direct Conjugates</h3>
                </GlassCard>
                <GlassCard textAlign={'center'} padding={'16px 32px 16px 32px'}>
                    <h3>Identifyn™ SRM Alexa Fluor™ Secondary</h3>
                </GlassCard>
            </WrapperSmallGap>
            </Header>
            <Content>
                <div className="gallery-tab-container">
                    <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} className={productType === "alexafluor" && 'tab-active'}
                        onClick={() => {
                            setProductType("alexafluor")
                            setFilters({
                                proteinTarget: {
                                    dropDown: filters.proteinTarget.dropDown,
                                    selectedOptions: [],
                                },
                                conjugateType: {
                                    dropDown: filters.conjugateType.dropDown,
                                    selectedOptions: [],
                                },
                                dye: {
                                    dropDown: filters.dye.dropDown,
                                    selectedOptions: [],
                                },
                                resolution: {
                                    dropDown: filters.resolution.dropDown,
                                    selectedOptions: [],
                                },
                                secondaryAntibodies: {
                                    dropDown: filters.secondaryAntibodies.dropDown,
                                    selectedOptions: '',
                                }
                            })
                            setProteinSearch('')
                            setCurrentPage(0)
                        }}
                    >Identifyn™ SRM Alexa Fluor™ Secondary Fluorescent Antibodies</button>
                    <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={productType === "primary" && 'tab-active'}
                        onClick={() => {
                            setProductType("primary")
                            setFilters({
                                proteinTarget: {
                                    dropDown: filters.proteinTarget.dropDown,
                                    selectedOptions: [],
                                },
                                conjugateType: {
                                    dropDown: filters.conjugateType.dropDown,
                                    selectedOptions: [],
                                },
                                dye: {
                                    dropDown: filters.dye.dropDown,
                                    selectedOptions: [],
                                },
                                resolution: {
                                    dropDown: filters.resolution.dropDown,
                                    selectedOptions: [],
                                },
                                secondaryAntibodies: {
                                    dropDown: filters.secondaryAntibodies.dropDown,
                                    selectedOptions: '',
                                }
                            })
                            setProteinSearch('')
                            setCurrentPage(0)
                        }}
                    >Primary Antibodies</button>
                    <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={productType === "primary_conjugate" && 'tab-active'}
                        onClick={() => {
                            setProductType("primary_conjugate")
                            setFilters({
                                proteinTarget: {
                                    dropDown: filters.proteinTarget.dropDown,
                                    selectedOptions: [],
                                },
                                conjugateType: {
                                    dropDown: filters.conjugateType.dropDown,
                                    selectedOptions: [],
                                },
                                dye: {
                                    dropDown: filters.dye.dropDown,
                                    selectedOptions: [],
                                },
                                resolution: {
                                    dropDown: filters.resolution.dropDown,
                                    selectedOptions: [],
                                },
                                secondaryAntibodies: {
                                    dropDown: filters.secondaryAntibodies.dropDown,
                                    selectedOptions: '',
                                }
                            })
                            setProteinSearch('')
                            setCurrentPage(0)
                        }}
                    >Identifyn™ SRM Alexa Fluor™ Conjugated Primary Antibodies </button>
                    <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={productType === "western" && 'tab-active'}
                        onClick={() => {
                            setProductType("western")
                            setFilters({
                                proteinTarget: {
                                    dropDown: filters.proteinTarget.dropDown,
                                    selectedOptions: [],
                                },
                                conjugateType: {
                                    dropDown: filters.conjugateType.dropDown,
                                    selectedOptions: [],
                                },
                                dye: {
                                    dropDown: filters.dye.dropDown,
                                    selectedOptions: [],
                                },
                                resolution: {
                                    dropDown: filters.resolution.dropDown,
                                    selectedOptions: [],
                                },
                                secondaryAntibodies: {
                                    dropDown: filters.secondaryAntibodies.dropDown,
                                    selectedOptions: '',
                                }
                            })
                            setProteinSearch('')
                            setCurrentPage(0)
                        }}
                    >Western Blots</button>
                </div>
                <div className="header-content">
                    <div className='filter-btn'>
                        <WrapperSmallGap flexDirection={'row'} gap={'10px'} alignItems={'center'} alignItemMobile={'start'} justifyContent={'start'}>
                        <button className='btn'
                            style={{ background: openFilter && 'rgba(255, 141, 36, 0.28)', width:'fit-content' }}
                            onClick={() => setOpenFilter(!openFilter)}
                        >{TotalSelectedFilters(filters) > 0 ? <img src={toAbsoluteUrl('/media/filterapplied.png')} style={{ width: '20px'}} alt='' /> : <i className='fal fa-filter' />}All Filters {TotalSelectedFilters(filters) > 0 ? `(${TotalSelectedFilters(filters)})` : ''}</button>
                        <h5><em>Filter by Antibody, Dye, and Resolution</em></h5>
                        </WrapperSmallGap>
                        {openFilter && <div className="filter-dropdown" ref={dropdownRef}>
                            <div className="filter-dropdown-container">
                                <div className="filter-header">
                                    <h3>Filters</h3>
                                    {(filters?.proteinTarget.selectedOptions.length > 0 || filters?.conjugateType.selectedOptions.length > 0 || filters?.dye.selectedOptions.length > 0 || filters?.resolution.selectedOptions.length > 0) && <h6 style={{ cursor: 'pointer', color: 'var(--primary-color)', fontWeight: 600 }}
                                        onClick={() => {
                                            setFilters({
                                                proteinTarget: {
                                                    dropDown: filters.proteinTarget.dropDown,
                                                    selectedOptions: [],
                                                },
                                                conjugateType: {
                                                    dropDown: filters.conjugateType.dropDown,
                                                    selectedOptions: [],
                                                },
                                                dye: {
                                                    dropDown: filters.dye.dropDown,
                                                    selectedOptions: [],
                                                },
                                                resolution: {
                                                    dropDown: filters.resolution.dropDown,
                                                    selectedOptions: [],
                                                },
                                                secondaryAntibodies: {
                                                    dropDown: filters.secondaryAntibodies.dropDown,
                                                    selectedOptions: '',
                                                }
                                            })
                                            setProteinSearch('')
                                            setCurrentPage(0)
                                        }}
                                    >Reset All</h6>
                                    }
                                </div>
                                {(productType === 'primary' || productType === 'primary_conjugate') && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                proteinTarget: {
                                                    ...prevFilters.proteinTarget,
                                                    dropDown: !prevFilters.proteinTarget.dropDown
                                                }
                                            }))

                                        }}
                                    >
                                        <h4>Protein Target {filters?.proteinTarget?.selectedOptions.length > 0 && <span>{filters?.proteinTarget?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.proteinTarget?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        proteinTarget: {
                                                            ...prevFilters.proteinTarget,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.proteinTarget?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.proteinTarget?.dropDown && <Input type='text' placeholder='Search' clear={proteinSearch} onClear={() => setProteinSearch('')} search style={{ width: '100%', height: '40px', paddingLeft: '30px' }} value={proteinSearch} onChange={(e) => setProteinSearch(e.target.value)} />}
                                    {filters?.proteinTarget?.dropDown && <div className="filter-type-container" style={{ maxHeight: tempProteinsList.length > 0 ? '300px' : '300px' }}>
                                        {tempProteinsList.length > 0 ? tempProteinsList?.map((item) => {
                                            return <div className="two-column">
                                                <Checkbox label={item} value={item} checked={filters.proteinTarget.selectedOptions.includes(item)}
                                                    onChange={() => {
                                                        if (filters.proteinTarget.selectedOptions.includes(item)) {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                proteinTarget: {
                                                                    ...prevFilters.proteinTarget,
                                                                    selectedOptions: filters.proteinTarget.selectedOptions.filter(option => option !== item)
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        } else {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                proteinTarget: {
                                                                    ...prevFilters.proteinTarget,
                                                                    selectedOptions: [...prevFilters.proteinTarget.selectedOptions, item]
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        }
                                                    }}
                                                />
                                                {/* <p>70</p> */}
                                            </div>
                                        })
                                            : <div className='no-orders'>
                                                <span className='icon'>
                                                    <i class="fal fa-boxes"></i>
                                                </span>
                                                <p style={{ color: '#6c6a73', fontWeight: 400, fontSize: '16px' }}>There are no results. <br />Try to broaden your search.</p>
                                            </div>
                                        }
                                        {/* <div className="two-column">
                                        <Checkbox label='Option 2' />
                                        <p>48</p>
                                    </div>
                                    <div className="two-column">
                                        <Checkbox label='Option 3' />
                                        <p>48</p>
                                    </div> */}
                                    </div>
                                    }
                                </div>
                                }
                                {productType === 'primarys' && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                conjugateType: {
                                                    ...prevFilters.conjugateType,
                                                    dropDown: !prevFilters.conjugateType.dropDown
                                                }
                                            }))
                                        }}
                                    >
                                        <h4>Conjugate Type {filters?.conjugateType?.selectedOptions.length > 0 && <span>{filters?.conjugateType?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.conjugateType?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        conjugateType: {
                                                            ...prevFilters.conjugateType,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.conjugateType?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.conjugateType?.dropDown && <div className="filter-type-container">
                                        <div className="two-column">
                                            <Checkbox label='Primary Conjugate' value={'Primary Conjugate'} checked={filters.conjugateType.selectedOptions.includes('Primary Conjugate')}
                                                onChange={(e) => {
                                                    if (!filters.conjugateType.selectedOptions.includes('Primary Conjugate')) {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: [...filters.conjugateType.selectedOptions, e.target.value]
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    } else {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: filters.conjugateType.selectedOptions.filter(item => item !== 'Primary Conjugate')
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    }
                                                }} />
                                            {/* <p>70</p> */}
                                        </div>
                                        <div className="two-column">
                                            <Checkbox label='Secondary Conjugate' value={'Secondary Conjugate'} checked={filters.conjugateType.selectedOptions.includes('Secondary Conjugate')}
                                                onChange={(e) => {
                                                    if (!filters.conjugateType.selectedOptions.includes('Secondary Conjugate')) {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: [...filters.conjugateType.selectedOptions, e.target.value]
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    } else {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: filters.conjugateType.selectedOptions.filter(item => item !== 'Secondary Conjugate')
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    }
                                                }}
                                            />
                                            {/* <p>48</p> */}
                                        </div>
                                    </div>
                                    }
                                </div>
                                }
                                {productType !== 'primary' && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                dye: {
                                                    ...prevFilters.dye,
                                                    dropDown: !prevFilters.dye.dropDown
                                                }
                                            }))

                                        }}
                                    >
                                        <h4>Dye {filters?.dye?.selectedOptions.length > 0 && <span>{filters?.dye?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.dye?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        dye: {
                                                            ...prevFilters.dye,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.dye?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.dye?.dropDown && <div className="filter-type-container">
                                        {
                                            dyeFilters.map(item => {
                                                if(productType === 'western' && item.value === 'Control') return null
                                                return <div className="two-column">
                                                    <Checkbox label={item.label} value={item.value} checked={filters.dye.selectedOptions.includes(item.value)}
                                                        onChange={(e) => {
                                                            if (!filters.dye.selectedOptions.includes(item.value)) {
                                                                setFilters(prevFilters => ({
                                                                    ...prevFilters,
                                                                    dye: {
                                                                        ...prevFilters.dye,
                                                                        selectedOptions: [...filters.dye.selectedOptions, e.target.value]
                                                                    }
                                                                }))
                                                                setCurrentPage(0)
                                                            } else {
                                                                setFilters(prevFilters => ({
                                                                    ...prevFilters,
                                                                    dye: {
                                                                        ...prevFilters.dye,
                                                                        selectedOptions: filters.dye.selectedOptions.filter(_i => _i !== item.value)
                                                                    }
                                                                }))
                                                                setCurrentPage(0)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }    
                                        )
                                        }
                                    </div>
                                    }
                                </div>
                                }
                                {productType !== 'western' && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                resolution: {
                                                    ...prevFilters.resolution,
                                                    dropDown: !prevFilters.resolution.dropDown
                                                }
                                            }))

                                        }}
                                    >
                                        <h4>Resolution {filters?.resolution?.selectedOptions.length > 0 && <span>{filters?.resolution?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.resolution?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        resolution: {
                                                            ...prevFilters.resolution,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.resolution?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.resolution?.dropDown && <div className="filter-type-container">
                                        {resolutionsFilters?.map(item => (
                                            <div className="two-column">
                                                <Checkbox label={item.label} value={item.value} checked={filters.resolution.selectedOptions.includes(item.value)}
                                                    onChange={(e) => {
                                                        if (!filters.resolution.selectedOptions.includes(item.value)) {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                resolution: {
                                                                    ...prevFilters.resolution,
                                                                    selectedOptions: [...filters.resolution.selectedOptions, e.target.value]
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        } else {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                resolution: {
                                                                    ...prevFilters.resolution,
                                                                    selectedOptions: filters.resolution.selectedOptions.filter(_i => _i !== item.value)
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                                }
                                {(productType === 'alexafluor' || productType === 'western') && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                secondaryAntibodies: {
                                                    ...prevFilters.secondaryAntibodies,
                                                    dropDown: !prevFilters.secondaryAntibodies.dropDown
                                                }
                                            }))

                                        }}
                                    >
                                        <h4>Secondary Antibodies {filters?.secondaryAntibodies?.selectedOptions.length > 0 && <span>{filters?.secondaryAntibodies?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.secondaryAntibodies?.selectedOptions?.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        secondaryAntibodies: {
                                                            ...prevFilters.secondaryAntibodies,
                                                            selectedOptions: ''
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.secondaryAntibodies?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.secondaryAntibodies?.dropDown && <div className="filter-type-container" style={{gap:'16px'}}>
                                        {secondaryAntibodiesFilters?.map(item => (
                                            <div className='subfilter-type-container'>
                                                <h4>{item.name}</h4>
                                                {item.subFilters?.map(subItem => (
                                                    <div className="two-column">
                                                        <Checkbox label={subItem.label} value={subItem.value} checked={filters.secondaryAntibodies.selectedOptions.includes(subItem.value)}
                                                            onChange={(e) => {
                                                                if (!filters.secondaryAntibodies.selectedOptions.includes(subItem.value)) {
                                                                    setFilters(prevFilters => ({
                                                                        ...prevFilters,
                                                                        secondaryAntibodies: {
                                                                            ...prevFilters.secondaryAntibodies,
                                                                            selectedOptions: [...filters.secondaryAntibodies.selectedOptions, e.target.value]
                                                                        }
                                                                    }))
                                                                    setCurrentPage(0)
                                                                } else {
                                                                    setFilters(prevFilters => ({
                                                                        ...prevFilters,
                                                                        secondaryAntibodies: {
                                                                            ...prevFilters.secondaryAntibodies,
                                                                            selectedOptions: filters.secondaryAntibodies.selectedOptions.filter(_i => _i !== subItem.value)
                                                                        }
                                                                    }))
                                                                    setCurrentPage(0)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                            <hr style={{ height: '1px', background: '#EDEDED' }} />
                            <div className="filter-btn-container">
                                <button className="cancel-btn btn"
                                    onClick={() => {
                                        setOpenFilter(false)
                                    }}
                                >Cancel</button>
                                <button className="btn"
                                    onClick={() => {
                                        setOpenFilter(false)
                                        // setFilterApplied(Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0) > 0 ? true : false)
                                        // setTempFilters(filters)
                                    }}
                                >Show {TotalSelectedFilters(filters) > 0 ? `(${TotalSelectedFilters(filters)})` : 'All'}</button>
                            </div>
                        </div>
                        }
                    </div>
                    <h5><span>{images.totalcount}</span> images</h5>
                </div>
                {filterApplied && <div className="filters-applied-container">
                    {search && <div className="filters">
                        <span>Search:</span> {search} <i className='fal fa-times' onClick={() => setSearch('')}></i>
                    </div>
                    }
                    {Object.entries(filters).map(([parentName, { selectedOptions }]) => {
                        const options = Array.isArray(selectedOptions) ? selectedOptions : [];
                        if (options.length > 0) {
                            return (
                                <div key={parentName} className="filters">
                                    <span>{parentName === 'dye' ? 'Dye' : parentName === 'proteinTarget' ? 'Protein Target' : parentName === 'conjugateType' ? 'Conjugate Type' : parentName === 'secondaryAntibodies'
                                        ? 'Secondary Antibodies' : 'Application'}:</span>
                                    {options.map((option, index) => {
                                        if (parentName === 'secondaryAntibodies') {
                                            option = option.replace(/host=/, '');
                                            option = option.replace(/species_reactivity=/, '');
                                            option = option.replace(/&chain_specification=/, ' ');
                                            option = option.replace('&', ' ');
                                        }

                                        return (
                                            <>
                                                {option}
                                                {parentName === 'dye' && option !== 'Control' && ' nm'}
                                                {index !== options.length - 1 && '; '}
                                            </>
                                        );
                                    })}
                                    <i className='fal fa-times'
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                [parentName]: {
                                                    ...prevFilters[parentName],
                                                    selectedOptions: []
                                                }
                                            }))
                                            setCurrentPage(0)
                                            setTempFilters(prevFilters => ({
                                                ...prevFilters,
                                                [parentName]: {
                                                    ...prevFilters[parentName],
                                                    selectedOptions: []
                                                }
                                            }))
                                        }}
                                    ></i>
                                </div>
                            );
                        }
                        return null;
                    })
                    }
                </div>
                }
                <div id='gallery-grid'></div>
                {images && images?.products?.length > 0 && <div className="images-grid-container" style={{ display: isLoading ? 'flex' : 'none' }}>
                    {Array(17).fill()
                        .map((item, index) => (
                            <Skeleton key={index} height={171} width={171} />
                        ))
                    }
                </div>
                }
                {images && images?.products?.length > 0 ? <>
                    <div  className="images-grid-container" style={{ visibility: isLoading ? 'hidden' : 'visible', height: isLoading ? '0px' : 'auto' }}>
                        {images && images?.products?.map((image, i) => (
                            isVideoFile(image) ?
                                <video src={getMp4Url(image?.url)} autoPlay loop muted playsInline
                                    onClick={() => {
                                        setModalName(image?.name)
                                        setSelectedProduct({
                                            metadata: {
                                                images: [image]
                                            }
                                        })
                                        setOpenMethodologyModal(true)
                                    }}

                                />
                                : <div key={image.product_id + i}>
                                    {isGif(image) ?
                                        <img src={image?.url} alt=""
                                            onClick={() => {
                                                setModalName(image?.name)
                                                setSelectedProduct({
                                                    metadata: {
                                                        images: [image]
                                                    }
                                                })
                                                setOpenMethodologyModal(true)
                                            }} />
                                        :
                                        <img
                                            src={`${process.env.REACT_APP_GALLERY_URL}500:550:${image?.url?.split('.com/')[1]}`}
                                            alt=""
                                            onClick={() => {
                                                setModalName(image?.name)
                                                setSelectedProduct({
                                                    metadata: {
                                                        images: [image]
                                                    }
                                                })
                                                setOpenMethodologyModal(true)
                                            }}
                                        />
                                    }
                                </div>
                        ))}


                        {/* <ResponsiveMasonry
                            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4}}
                        >
                            <Masonry gutter="32px"
                                columnsCount={3} >

                            </Masonry>
                        </ResponsiveMasonry> */}
                    </div>
                    {images?.totalcount / images.limit > 1 && <Pagination total={images?.totalcount / images.limit} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
                </>
                    : <div className='no-orders'>
                        <span className='icon'>
                            <i class="far fa-image"></i>
                        </span>
                        <p>No Images Yet.</p>
                    </div>
                }
            </Content>
            <p className='container' style={{ margin: '0px 0 16px 0' }} id='gallery'>Identifyn™ humbly wishes that this collective enables the global academic and non-profit research community. <br /> <br />
                    Copy or redistribution of these images, methods, acquisition parameters, or general presentation for commercial use by for-profit entities is prohibited without prior written consent, or express license.
            </p>
            <Footer />
            <div className="print-map" style={{ display: 'none' }}>
                <Coa reference={coARef} selectedProduct={previewProduct} productCategory={previewProduct?.mid_code?.search("PA") >= 0 ? 'PA' : previewProduct?.mid_code?.search("SA") >= 0 ? 'SA' : 'DC'} />
            </div>
            {openMethodologyModal && <MethodologyModal setOpenMethodologyModal={setOpenMethodologyModal} selectedProduct={selectedProduct} modalName={modalName} setModalName={setModalName} galleryView={true} setPreviewProduct={setPreviewProduct} setOpenItemPreviewModal={setOpenItemPreviewModal} />}
            {openItemPreviewModal && <ItemPreviewDrawer previewProduct={previewProduct} setPreviewProduct={setPreviewProduct} coARef={coARef} setOpenItemPreviewModal={setOpenItemPreviewModal} setModalName={setModalName} setSelectedProduct={setSelectedProduct} />}
        </Layout>
    )
}

export default Index