import styled from "styled-components";

export const AboutHeader = styled.div`
margin-top: 20px;
h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;

    span{
        color: var(--primary-color);
    }
}
`

export const AboutContent = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;

.azure-section-four-container{
background: linear-gradient(0deg, rgba(241, 238, 252, 0.48) 0%, rgba(241, 238, 252, 0.48) 100%), #FFF;

h2{
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: 60px;
text-align: center;
}

h5{
font-size: 20px;
line-height: 28px;
font-weight: 700;
}

h4{
font-size: 24px;
line-height: 28px;
font-weight: 700;
}

p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
text-align: center;

span{
    color: var(--primary-color);
    font-weight: 700;
}

a{
text-decoration: underline;
color:#3f1bd0;
}

}


.calibration-table-container{
display: grid;
gap: 24px;
margin-top: 36px;

@media screen and (max-width:500px) {
   width: 95%;
}

.column{
display: grid;
grid-template-columns:repeat(4, 100px);
gap:10px;
align-items: center;

@media screen and (max-width:500px) {
    grid-template-columns: repeat(4, 85px);
}

h5{
span{
font-weight: 400;
}
}


}

}

}

.calibration-section-container{
background: #fff;

h3{
    color:#1e1e1e;
span{
    background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
}
}


.methods-header{
    display: grid;
    grid-template-columns: 150px auto;
    gap: 50px;
    width: 100%;

@media screen and (max-width:1100px) {
    grid-template-columns: 120px auto;
    gap: 5px;
}

.inner-header{
display: flex;
justify-content: space-between;
width: 100%;
}


p{
text-align: left;
display: flex;
gap: 5px;
align-items: start;

span{
color:#1e1e1e;
font-weight: 400;
}

}




}


.left-container > .solutions-text > p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
width: 100%;

span{
    color: var(--primary-color);
    font-weight: 700;
}
}

.card1,.card2 > p{
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;

span{
    font-weight: 700;
    color: var(--primary-color);
}

.purple{
    color: #3F1BD0;
}

}

@media screen and (min-width:2160px) {
    .background-image{
        height: 800px !important;
    }
}



.services-section-two-container {
    margin: 0px 0 32px 0 ;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: start;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width:1100px) {
        grid-template-columns: 1fr;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }


    .left-container,
    .right-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
        height: 100%;
    }

    .card1,
    .card2,
    .card3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        background: linear-gradient(0deg, rgba(241, 238, 252, 0.48) 0%, rgba(241, 238, 252, 0.48) 100%), #FFF;
        border-radius: 12px;
        padding: 44px 96px;
        /* justify-content: center; */
        height: 100%;

        @media screen and (max-width:1100px) {
        padding: 44px 1.2rem;
        height: max-content !important;

        h3{
            font-size: 30px !important;
        }
    }

        h3 {
            font-size: 34px;
            line-height: 50px;
            color: #3F1BD0;
            text-align: left;
            margin-bottom: 16px;
        }

        h4{
            font-size: 24px;
            line-height: 50px;
            color: #3F1BD0;
            text-align: left;
            margin-bottom: 16px;
        }

        p{
        margin-bottom: 16px;
        }

        .points-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 100%;
        }

        .points {
            display: flex;
            align-items: start;
            gap: 12px;
            position: relative;
            width: 100%;


            img {
                width: 12px;
                height: 12px;
                transform: translateY(5px);
            }

            a{
            width: 100%;
            }

            p {
                font-size: 20px;
                line-height: 24px;
                width: 100%;
                margin-bottom: 0;

                span {
                    color: #3F1BD0;
                    font-weight: 600
                }
            }
        }
    }

    .card3{
        background:rgba(237, 237, 237, 0.2) !important;
    }

    .card2 {
        background: linear-gradient(0deg, rgba(255, 242, 229, 0.48) 0%, rgba(255, 242, 229, 0.48) 100%), #FFF;

        h3 {
            color: var(--primary-color);
        }
        h4{
            color: var(--primary-color);
        }

        .points {
            p {
                span {
                    color: var(--primary-color)
                }
            }
        }
    }

}

.thermo-second-container{
.left-container, .right-container {
    .card1,.card2{
    @media screen and (max-width:1100px) {
        height: max-content !important;
    }
}     
} 
}

`