import styled from "styled-components";
import AngleDown  from "../../../assets/angle-down.png";
import { toAbsoluteUrl } from "../../../utils/assetHelper";

export const Wrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;

.pathway-header-container{
width: 100%;
height: 64px;
position: fixed;
background: #fff;
filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
backdrop-filter: blur(18px);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
/* margin-top: 75px; */
left: 0;
top: 75px;
padding: 12px 24px;
display: flex;
justify-content: space-between;
z-index: 9;

@media screen and (max-width: 1100px) {
 flex-direction: column;
 gap: 10px;
 height: max-content;
}

@media screen and (min-width: 2160px) {
  padding-left: 25rem;
  padding-right: 25rem;
}


.print-btn{
display: inline-flex;
height: 40px;
padding: 12px 10px;
gap: 10px;
justify-content: center;
align-items: center;
background: #FFF2E5;
border-radius: 12px;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
color: var(--primary-color);
cursor: pointer;

&:hover{
  background-color: rgba(255, 141, 36, 0.28);
  
}
}

.header-search-container{
    width: auto;
    display: flex;
    align-items: center;
    gap: 10px;

    .btn{
        width: 300px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

   input[type="text"]{
      @media screen and (max-width: 1100px) {
        width: 100% !important;
      }
    }

    select {
                display: flex;
                height: 40px;
                padding: 10px 16px;
                outline: unset !important;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #28272e;
                width: 100%;
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background:#FCFCFC;
                backdrop-filter: blur(19px);
                width: 90px;
                justify-content: center;
                align-items: center;

                @media (max-width: 768px) {
                    width: -webkit-fill-available;
                }

                @media (max-width: 1000px) {
                    width: -webkit-fill-available;
                }

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(${AngleDown});
                background-repeat: no-repeat,
                repeat;
                background-position: right 0.7em top 50%,
                0 0;
                background-size: 0.65em auto,
                100%;
            }
}


.bread-cumbs-container{
  display: flex;
  gap:24px;
  align-items: center;

  @media screen and (max-width: 1100px) {
  flex-wrap: wrap;
  gap: 6px;
}
    .tab-container{
        width: 80px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EDEDED;
        border-radius: 12px;

button{
    display: block;
  background-color:#EDEDED;
  color: #000;
  text-align: center;
  padding: 1rem 1.2rem;
  font-size: 1rem;
  font-weight: 500;
  height: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
    }

    .tab-active{
        background-color: #FFF2E5;
        color: var(--primary-color);
        border-radius: 12px;
    }

    }
a{
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: #6c6a73;
display: flex;
gap: 10px;
align-items: center;

i{
  font-size: 13px;
}

.dna-icon{
  transform: rotate(30deg);
}

}


}



}

.map-container{
width: 100%;
height: 100vh;
display: flex;
/* gap:20px; */

.map-showcase{
  width: 100%;
  height: 100%;
  padding: 140px 0px 24px 24px;
  position: relative;

  @media screen and (max-width: 1100px) {
    padding-top: 200px;
  }

.react-transform-wrapper{
  width: 100%;

.react-transform-component{
  width: max-content;
}

}


}

.active-map-show{
  width: 70%;
}

.product-details{
  width: 0%;
  height: 100%;
  background: #FFF;
box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
display: flex;
overflow-y: hidden;
transform: translateX(100vw);
padding-top: 140px;

}

.active-product-show{
  width: 600px;
  transform: translateX(0);


@media screen and (min-width: 2160px) {
  width: 30%;
}

}

.active-product-show-mobile{
position: absolute;
width: 100vw;
height: 100vh;
left: 0;
bottom:0;
top: 0;
padding-top: 0;
}


.product-details-wrapper{
  width: 100%;
  height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 24px;

    @media screen and (max-width:768px) {
      padding-top: 270px;
      padding-bottom: 20px;
    }

    @media screen and (min-width:768px) and (max-width:1100px) {
      padding-top: 220px;
    }

    @media screen and (min-width: 1111px) and  (max-width:1200px) {
      padding-top: 180px;
    }

    .select-dye {
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                backdrop-filter: blur(19px);
                display: flex;
                height: 48px;
                padding: 10px 12px;
                outline: unset !important;
                /* border: unset !important; */
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #28272e;
                width: 100%;

                @media (max-width: 768px) {
                    width: -webkit-fill-available;
                }

                @media (max-width: 1000px) {
                    width: -webkit-fill-available;
                }

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(${toAbsoluteUrl("/media/angle-down.png")});
                background-repeat: no-repeat,
                repeat;
                background-position: right 0.7em top 50%,
                0 0;
                background-size: 0.65em auto,
                100%;
            }
}

.function-container{
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: max-content;
  background: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);


.function-wrapper{
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 13px 24px;
  gap: 50px;
  width: 100%;

  h4{
  font-size: 16px;
  font-weight: 700;
  color: #3F1BD0;
}

.function{
  width: 60%;
  display: flex;
  gap: 8px;

  .function-text{
    color:#1e1e1e;
    font-size: 17px;
    font-weight: 400;
    height: max-content;
    max-height: 100px;
    overflow-y: auto;
    display: grid;
    gap: 8px;
    span{
      color: var(--primary-color);
    }

    p{
    overflow: hidden;
    }
    ul{
    list-style-type: circle;
    display: grid;
    gap: 8px;
  }
  ol{
    list-style-type: decimal;
    display: grid;
    gap: 8px;
    margin-left: 20px;
  }
}
}

.references{
  display: flex;
  gap: 8px;
  width: 25%;
  /* align-items: center; */


.references-list{
  display: flex;
  gap: 8px;
  a{
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-color)
  }
}


}

.key{
position: relative;
width: auto;
display: flex;
gap:8px;
height: max-content;
align-items: center;
color:var(--primary-color);
cursor: help;
span{
font-weight: 700;
}
}

.tip-img{
position: absolute;
bottom:85%;
right: -10px;
width: 327px;
height: 372px;
pointer-events: none;
}

}

}

.key-button{
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 24px;
  border-radius: 12px;
  background:#FFF2E5;
  color:var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  padding: 8px 10px;
}

.tip-img-outside{
position: absolute;
bottom: 40px;
left: 0px;
width: 327px;
height: 372px;
pointer-events: none;
}

}

.list-container{
width: 100%;
height: 100%;
overflow-y: auto;
padding: 20px 24px 60px 24px;
margin-top: 140px;


.products-cards-container{
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
.product-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    border-radius: 12px;
    background: rgba(245, 245, 245, 0.80);
    padding: 12px;

.product-double-column{
  display: flex;
  justify-content: space-between;
  gap: 8px;

.label-name{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

}

h5{
font-size: 14px;
color: #6C6A73;
font-weight: 400;
}

h4{
font-size: 16px;
font-weight: 400;
}


}
}



.mainTable{
  border-collapse: collapse;
  width: 100%;
  vertical-align: middle;
  margin-bottom: 20px;

  @media screen and (max-width: 1100px) {
    width: max-content;
  }


 td, th {
  padding: 12px 14px;
}

tr:nth-child(odd){
border-radius: 8px;
background: rgba(245, 245, 245, 0.80);
}
 th {
  padding-top: 12px;
  padding-bottom: 20px;
  text-align: left;
  background-color: white;
  color: #6C6A73;
  font-size: 14px;
  font-weight: 400;

&:last-child{
    text-align: right;
}

}

td{
color: #000;
font-size: 14px;
font-weight: 400;
line-height: 20px;
    &:last-child{
        text-align: right;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    &:first-child{
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
}
    
    }


    .applications{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    
    span{
    background: linear-gradient(0deg, rgba(255, 141, 36, 0.16) 0%, rgba(255, 141, 36, 0.16) 100%), #FFF;
    color:var(--primary-color);
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    font-weight: 700;
    }
}


.coa{
display: flex;
height: 40px;
padding: 12px 10px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background:#EDEDED;
color: #1E1E1E;
font-weight: 500;
font-size: 16px;
height: 40px !important;

&:hover{
  background-color: #E2E2E2;
}
}

.add-btn{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    background: #FFF2E5;
    border-radius: 12px;
    height: 40px !important;

    &:hover{
  background-color: rgba(255, 141, 36, 0.28);
  
}
}

.buy-btn{
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px !important;
}



}



`