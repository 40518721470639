import React from 'react'
import Layout from "../../../components/layout"
import "./style.scss"
import { toAbsoluteUrl } from "../../../utils/assetHelper"
import { Footer } from '../../../components/Footer'

const Index = () => {
    return (
        <Layout>
            <div className="container title-container">
                <img src={toAbsoluteUrl("/media/partners/acquifer.png")} alt="" />
            </div>
            <div className="container acquifer-container">
                <div className="left-container">
                    <div className="solutions-text">
                        <h2>ACQUIFER HIVE</h2>
                        <p>Identifyn™ currently empowers <span>data sharing throughout the global scientific community</span> by deploying ACQUFER HIVE technology. The ACQUIFER HIVE provides a high-speed, <span>centralized data repository</span> accessible to our customers, enabling <span>offline image analysis</span> of your data set <span>from your lab</span> without the need to duplicate or manually move large image sets. Identifyn's™ ACQUIFER HIVE system provides <span>access to cutting-edge image analysis software</span> from our partners, allowing remote and secure analysis via Zen Blue™, Vutara SRX™, Azure Spot™, and open-source software like Image J.
                        </p>
                    </div>
                </div>
                <div className="right-container" style={{position:'relative'}}>
                    <img src={toAbsoluteUrl("/media/acquifer/acquifer1.png")} alt="" className='bruker-hero-img' style={{objectFit:'contain'}} />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, color: '#1e1e1e', fontSize:'10px', position:'absolute', bottom:'-50px', right:'5%' }}>Image Courtesy Bruker Acquifer</small>
                </div>
            </div>
            <div className="container acquifer-section-two-container">
                <div className="left-container">
                    <div className="card1" style={{height:'310px'}}>
                        <h3>Super Resolution Microscopy Employing Vutara SRX™</h3>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Vutara VXL, <span>Single-Molecule Localization Microscopy (SMLM).</span></p>
                        </div>
                    </div>
                    <div className="card2" style={{height:'390px'}}>
                        <h3>Conventional Microscopy Employing Zeiss Zen Blue™ </h3>
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Zeiss Axio Imager <span> Widefield </span> Microscopy.</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Zeiss Apotome 3 <span>Structured Illumination</span> Microscopy (SIM).</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p> Zeiss LSM 900 <span>Confocal</span> Microscopy.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-container">
                    <div className="card2" style={{ height:'418px'}}>
                        <h3>Super Resolution Microscopy (SRM) Employing Zen Blue™ </h3>
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Zeiss LSM 900 <span>Airyscan SRM.</span></p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Zeiss Elyra 5 Structured Illumination Microscopy <span>SIM SRM.</span></p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p> Zeiss Elyra 5 Lattice Structured Illumination Microscopy <span>SIM² SRM.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="card1" style={{height:'282px'}}>
                        <h3>Multiplex Fluorescent Imaging Employing Azure Spot™</h3>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Azure Biosystems, <span> Sapphire FL Imager.</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container acquifer-section-three-container">
                <div className="card1">
                    <h3>Imaging</h3>
                    <div className="images-container">
                        <div className="image">
                            <img src={toAbsoluteUrl("/media/partners/bruker.png")} alt="" width={162} height={80} />
                            <p>SMLM – Super Resolution</p>
                        </div>
                        <div className="image">
                            <img src={toAbsoluteUrl("/media/partners/azure.png")} alt="" width={190} height={57} />
                            <p>Azure Sapphire – Multiplex IF​</p>
                        </div>
                        <div className="image">
                            <img src={toAbsoluteUrl("/media/partners/zeiss.png")} alt="" width={86} height={80} />
                            <p>Apotome SIM</p>
                            <p>Confocal</p>
                            <p>Airyscan – SRM</p>
                            <p>Elyra 5 SIM – SRM</p>
                            <p>Elyra 5 SIM<sup>2</sup> – SRM</p>
                        </div>
                        <div className="image">
                        <small style={{ font:'Instrument Sans', fontWeight: 400, color: '#1e1e1e', fontSize:'10px'}}>Logo Use Courtesy Of Bruker, Azure Biosystems and Zeiss </small>
                        </div>
                    </div>
                </div>
                <div className="card2">
                    <h3>Data Management</h3>
                    <img src={toAbsoluteUrl("/media/acquifer/acquifer1.png")} alt="" />
                    <p>Acquifer HIVE Data Management</p>
                </div>
                <div className="card3">
                    <h3>Global Connectivity</h3>
                    <img src={toAbsoluteUrl("/media/globe.png")} alt="" />
                    <p style={{lineHeight:'28px'}}>Remote Access to Large Data Files, Software, Analysis, and Data Capture Delivered to the Global Scientific Community, Academia, Industry, and Drug Discovery​</p>
                </div>
            </div>
            <div className="container acquifer-section-four-container">
                <h3>Identifyn's™ ongoing commitment to image acquisition technologies combined with HIVE data management ensures high-speed, effective project management with our academic and industry customers.</h3>
            </div>
            <div className="container acquifer-container" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2><span>Identifyn’s™ Acquifer HIVE</span> </h2>
                           <h3> Data Management System</h3>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/acquifer/acquifer2.png")} alt="" className='bruker-hero-img' style={{objectFit:'contain'}} />
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default Index