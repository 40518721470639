import React, {useState, useEffect, useContext} from 'react'
import Layout from '../../../components/layout'
import {Wrapper, AboutHeader, AssaySubHeader, AssayContent, WrapperSmallGap, HeadingContainer, CardContainer } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { AuthContext } from '../../../context/auth'
import { contactApi } from '../../../api'
import Input from "../../../components/Input"
import { CountryDropdown, StateDropdown } from '../../../components/DropDowns'
import axios from 'axios'
import Dropdown from "../../../components/Dropdown"
import { Footer } from '../../../components/Footer'

const Index = () => {
  const {setIsLoading, setNotification } = useContext(AuthContext)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('United States');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [topic, setTopic] = useState('Identifyn™ Assay Development');
  const [description, setDescription] = useState('');
  const [cities, setCities] = useState([]);


  useEffect(() => {
    if (state === undefined) return
    if (state) {
      axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
        country: country,
        state: state
      }).then((res) => {
        setCities(res.data.data)
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [state])



  const sendContact = async () => {
    setIsLoading(true)
if(email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)){
    const data = {
        first_name:firstName,
         last_name:lastName,
         email,
         company,
         country,
         state,
         city,
         topic,
         message:description,
         email_to:'services@identifyn.com'
     }
     const response = await contactApi(data)
     if(response?.status === 200) {
         setFirstName('')
         setLastName('')
         setEmail('')
         setCompany('')
         setCountry('United States')
         setState('')
         setCity('')
         setTopic('')
         setDescription('')
         setIsLoading(false)
         setNotification({
             type: 'success',
             message: 'Message sent successfully!'
         })      
     }else{
         setIsLoading(false)
         setNotification({
             type: 'red',
             message: 'Something went wrong, please try again!'
         })
     }
}else{
    setIsLoading(false)
    setNotification({
        type: 'red',
        message: 'Please enter a valid Email Address'
    })
}
}






  return (
    <Layout>
      <Wrapper>
      <div className="container title-container bio-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-users"></i>
        </span>
        <AboutHeader>
          <h1>Identifyn™ Assay Development</h1>
        </AboutHeader>
      </div>
      <AssaySubHeader>
        <div className='subhero-img'>
        <img src={toAbsoluteUrl("/media/services/header1.png")} alt="header1" />
        </div>
      </AssaySubHeader>
      <AssayContent>
        <HeadingContainer>
        <span>Let Identifyn™ Deploy the most Powerful Set of Tools in Scientific Industry​ combined with our Experience in Application, Data Analysis and Interpretation with our Proprietary Bench to Body™ Methodology in Realizing your Assay</span>
        </HeadingContainer>
        <CardContainer background={'#EDEDED'}>
        <h4>A Glimpse into Identifyn's™ ongoing Internal Assay Development through our Innovative Bench to Body™ Methodology!</h4>
        </CardContainer>
      <CardContainer background={'#FFF2E5'}  width={'60%'}  padding={'44px'} mb_h2={'16px'} mb_h4={'24px'} textAlignP={'center'}>
          <h2><span>Step 1</span></h2>
          <h4>Clinical samples (cells/tissues) are treated with chemotherapy pharmaceuticals and observed for various DNA damage protein responses as a measure of cell viability. The data is analyzed for multiple parameters to determine sample specific dosing effecting cellular proliferation and programmed cell death</h4>
          <p><span>This serves as a precursor to personalized clinical treatment</span></p>
      </CardContainer>
      <CardContainer background={'#FFF2E5'}  width={'60%'}  padding={'44px'} mb_h2={'16px'} mb_p={'16px'} textAlignP={'center'}>
          <h2><span>Step 2</span></h2>
          <p><span>Collect Raw Data from ​Microscopes that operate at​ various Spatial Resolutions</span></p>
          <WrapperSmallGap>
            <img src={toAbsoluteUrl("/media/assay/image1.png")} alt="" style={{ width:'100%', margin:'0 auto'}} />
          </WrapperSmallGap>
      </CardContainer>
      <CardContainer background={'#FFF2E5'}  width={'60%'}  padding={'44px'} mb_h2={'16px'} mb_p={'16px'} textAlignP={'center'} fontSizeP={'16px'}>
          <h2><span>Step 3</span></h2>
          <p><span>High through-put Cellular Statistics</span></p>
          <WrapperSmallGap flexDirection={'row'} alignItems={'center'} textAlignP={'left'}>
          <div>
              <p><span>Statistics on Cellular DNA Damage Positivity:</span> Precise single-cell fluorescence data of DNA damage proteins provides information on the percentage of DNA damage-positive cells obtained from low magnification imaging</p>
              <p><span>Cellular Viability Statistics:</span> Single cell fluorescence of Cell viability marker provides information on percent of cell population loss​</p>
          </div>
          <img src={toAbsoluteUrl("/media/assay/image2.png")} alt="" />
          </WrapperSmallGap>
      </CardContainer>
      <CardContainer background={'#FFF2E5'}  width={'60%'}  padding={'44px'} mb_h2={'16px'} mb_h4={'16px'} mb_p={'16px'} textAlignP={'left'} fontSizeP={'16px'}>
          <h2><span>Step 4</span></h2>
          <h4>Protein response and Cell Vialbility Analysis</h4>
          <p><span>Correlative Study:</span> The response of DNA repair proteins in the presence of chemotherapeutics and its correlation to cellular proliferation and programmed cell death</p>
          <WrapperSmallGap>
          <img src={toAbsoluteUrl("/media/assay/image3.png")} alt="" style={{ width:'70%', margin:'0 auto'}} />
          </WrapperSmallGap>
      </CardContainer>
      <CardContainer background={'#FFF2E5'}  width={'60%'} padding={'44px'} mb_h2={'16px'} mb_h4={'24px'} mb_h5={'24px'} fontWeightH5={'400'} textAlignP={'left'} fontSizeP={'16px'}>
          <h2><span>Step 5</span></h2>
          <h4>Extraction of Drug Dose and Time Dependence</h4>
          <h5>Recent studies supported by Dr. Bennett’s research suggest that a higher dosage, which results in abundant expression of DNA damage proteins, cannot be directly correlated with cell death. Our preliminary data indicate that lower chemotherapy dosages lead to reduced DNA damage protein expression but result in higher rates of cell death, comparable to those seen with high dosages (data confidential). This finding is crucial for patient health. Investigating the relationship between dosage, time, and protein expression at the single-molecule level will help identify the minimum dosage that is most effective for chemotherapy while improving patients' quality of life during treatment. High-throughput single-molecule imaging offers very high sensitivity and will also enhance our understanding of molecular pathways</h5>
          <WrapperSmallGap alignItems={'center'} marginBottom={'24px'}>
          <img src={toAbsoluteUrl("/media/assay/image4.png")} alt="" style={{ width:'60%'}} />
          <img src={toAbsoluteUrl("/media/assay/image5.png")} alt="" style={{ width:'70%'}} />
          <img src={toAbsoluteUrl("/media/assay/image6.png")} alt="" style={{ width:'70%'}} />
          </WrapperSmallGap>
          <p><span>Identifyn™ Rabbit anti-Human γH2Ax Recombinant Monoclonal, Human Fc, IgG</span> (Cat ID# - Pa-RR-000004) is visualized with <span>Identifyn™ SRM Alexa Fluor™ 647 Affinity Purified Goat Anti-Human, IgG (H + L)</span> (Cat ID# - SA 000058) in the nuclear compartment of damaged Hela cells using super resolution STORM technique on Identifyn’s™ Vutara VXL. The SRX software is used to render the localization data, perform DBSCAN clustering, volumetric study using Convex Hulls, and animation.</p>
      </CardContainer>
        <CardContainer background={'#FFF2E5'} width={'60%'}  padding={'44px 44px 0px 44px'} mb_h2={'16px'}>
          <h2><span>Step 6</span></h2>
          <img src={toAbsoluteUrl("/media/assay/image7.png")} alt="" style={{ width:'80%', margin:'0 auto 0 15%'}} />
        </CardContainer>
        <WrapperSmallGap width={'60%'}>
          <CardContainer background={'#FFF2E5'} padding={'44px'} mb_h2={'16px'} textAlignH5={'center'} fontWeightH5={'400'}>
          <h2><span>Early Detection and Continued Monitoring of Cancer Progression at the Sub-Cellular Level in Providing Optimal Personalized Medicine</span></h2>
          <h5>Identifyn™ provides powerful microscopy applications, expert biological insights, and unique data analysis tools that enable high-throughput analysis, ranging from diffraction-limited to super-resolution imaging. This is especially useful for studying live cells, tissues, and patient biopsies. This innovative approach offers valuable insights for clinicians, helping to reduce dependence on broad-spectrum chemotherapy treatments. It supports personalized medicine, minimizes risks, and enhances patient outcomes</h5>
          </CardContainer>
        </WrapperSmallGap>
        <WrapperSmallGap width={'90%'}>
        <HeadingContainer>
        <span>Contact Identifyn™ Today to Deploy the most Powerful Set of Tools in Scientific Industry​ combined with our Experience in Application, Data Analysis and Interpretation with our Proprietary Bench to Body™ Methodology in Realizing your Assay</span>
        </HeadingContainer>
        </WrapperSmallGap>
        <WrapperSmallGap>
          <img src={toAbsoluteUrl("/media/assay/image8.png")} alt="" style={{ width:'100%', borderRadius:'12px'}} />
        </WrapperSmallGap>
      </AssayContent>
      <div className="container contact-container" id='contact'>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Contact <span>Us</span></h2>
              {/* <p>Please contact IDENTiFYN™ using this from. Select topic from dropdown and briefly describe how we can help.
              </p> */}
            </div>
          </div>
          <div className="right-container">
            <div className="form-container">
              <div className="form-wrapper">
                <div
                  style={{ display: "block", width: "100%", marginTop: "20px", marginBottom: '20px' }}
                >
                  <div className="two-column">
                    <Input placeholder="Your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <Input placeholder="Your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <Input placeholder="Your Email" type='email' style={{ marginBottom: '20px' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Input placeholder="Your Company/Institution" style={{ marginBottom: '20px' }} optional value={company} onChange={(e) => setCompany(e.target.value)} />
                  <div className="three-column">
                    <CountryDropdown value={country} onChange={(value) => {
                      setCountry(value)
                    }} />
                    <StateDropdown value={state} country={country} placeholder={"State"} onChange={(value) => {
                      setState(value)
                    }} />
                    <select
                      data-kt-select2='true'
                      data-placeholder='City'
                      data-allow-clear='true'
                      data-kt-user-table-filter='two-step'
                      data-hide-search='true'
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value)
                      }}
                      placeholder='City'
                    >
                      <option disabled selected>City </option>
                      {cities && cities.length && cities?.map((city) => {
                        return <option key={city} value={city}>{city}</option>
                      })}
                    </select>
                  </div>
                  <Input style={{ marginBottom: '20px', pointerEvents:'none' }} value={topic} onChange={(e) => setTopic(e.target.value)} />
                  {/* <Dropdown
                    value={topic}
                    onChange={(e) => {
                      setTopic(e)
                    }}
                    style={{ marginBottom: '20px', width: '100%' }}
                    label={'Select Topic'}
                    options={
                      [
                        "Microscopy Support",
                        "Protein Chemistry Support",
                        "Primary Antibody",
                        "Primary Fluorescent Conjugates",
                        "Secondary Fluorescent Conjugates",
                        "Reagents & Glass",
                        "STORM Buffers",
                        "Kits",
                        "Account Support",
                        "Order Support",
                        "General Inquiry"
                      ]
                    }
                  /> */}
                  <textarea
                    style={{ resize: "none", borderRadius: "8px" }}
                    placeholder='Write your message here!'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <button class="btn" style={{ float: 'right' }}
                  disabled={!firstName || !lastName || !email || !country || !state || !city || !topic || !description}
                  onClick={sendContact}
                >Get In Touch!</button>
              </div>
            </div>
          </div>
      </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default Index