import React, {useContext} from 'react'
import Layout from "../../../components/layout"
import "./style.scss"
import { toAbsoluteUrl } from "../../../utils/assetHelper"
import {Footer} from "../../../components/Footer"
import { AuthContext } from '../../../context/auth'

const Index = () => {
    const {width} = useContext(AuthContext)
    return (
        <Layout>
            <div className="container zeiss-container">
                <img src={toAbsoluteUrl("/media/partners/zeiss.png")} alt="" width={84} />
            </div>
            <div className="container azure-container" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2>ZEISS</h2>
                        <p>Identifyn™ has chosen to <span>deploy the power and breadth of optical instrumentation and application</span> via our strategic alignment with Zeiss. Identifyn's™ <span>quest for resolution differentiation, application strategies, and validation of our antibody and fluorescent products</span> is captured by Zeiss instrumentation from cell culture through conventional microscopy to super-resolution. <span>Identifyn™ and Zeiss arguably created the world's most powerful microscopy validation methodology</span>.
                        </p>
                    </div>
                </div>
             {width > 1100 &&   <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss.png")} alt="" className='bruker-hero-img' />
                </div>
                }
            </div>
            <div className="container azure-container reverse" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{fontSize:'38px'}}><span>ZEISS Primovert HDcam with Labscope</span></h2>
                        <p>Identifyn™ deploys the power of the Zeiss Primovert with HDcam and Labscope software for rapid Live cell assessments and cell counting. Additionally, the Primovert allows for phase contrast and fluorescent imaging and excels in Identifyn's™ core competency, oncological pathway product, and assay development, beginning with qualified cell culture harvesting.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss1.png")} alt="" className='bruker-hero-img' />
                </div>
            </div>
            <div className="container azure-container" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{fontSize:'38px'}}><span>ZEISS Axioscope</span></h2>
                        <p>Identifyn™ begins our optical vetting via the Zeiss Axioscope. The Axioscope uses powerful illumination uniformity and high color fidelity via an X-Cite LED, AxioCam MRm, and specially tuned filters to provide the best image capture from 405 nm through 750 nm. Combined with Zen advanced imaging acquisition and analysis tools, Identifyn™ widefield resolution imaging provides confidence in immunofluorescence vetting and biological analysis.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss2.png")} alt="" className='bruker-hero-img'  />
                </div>
            </div>
            <div className="container azure-container reverse" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{fontSize:'38px'}}><span>ZEISS Apotome Structured Illumination Microscope (SIM)</span></h2>
                        <p>Identifyn™ vets their fluorescence-based commercial products via the Zeiss Apotome, combining its grid projection system with intricate mathematical modeling to capture conventional fluorescence images at near-confocal resolution while displaying complex optical sections through the specimen with the ability to recreate 3D structures. Identifyn™ Apotome SIM imaging provides confidence in immunofluorescence vetting and biological analysis.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss3.png")} alt="" className='bruker-hero-img' />
                </div>
            </div>
            <div className="container azure-container" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{fontSize:'38px'}}><span>ZEISS LSM 900 Confocal with Super Resolution AiryScan</span></h2>
                        <p>Identifyn™ deploys the Zeiss LSM 900 confocal microscope with an Airyscan detector to capture detailed 3D confocal and super resolution to ~120 nm (X, Y). Identifyn™ uses the enhanced detection offered by super-resolution to qualify target specificity for our primary antibody products and further validate our antibody conjugates' stability and brightness. Identifyn™ LSM 900 with Airyscan provides trust in the product functionality and biological analysis.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss4.png")} alt="" className='bruker-hero-img' />
                </div>
            </div>
            <div className="container azure-container reverse" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{fontSize:'38px'}}><span>ZEISS LSM 980 Confocal with Super Resolution AiryScan & NIR Capabilities</span></h2>
                        <p>Identifyn™ deploys the Zeiss LSM 980 confocal microscope with an Airyscan detector to capture detailed 3D confocal and super resolution to ~120 nm (X, Y). Additionally, Identifyn's™ LSM 980 is specially equipped to capture the Near-Infrared  (NIR) spectrum up to 900 nm. This enhanced detection offered by super-resolution and NIR allows Identifyn™ to qualify target specificity for our primary antibody products and further validate our antibody conjugates' stability and brightness in NIR. Identifyn™ LSM 980 with Airyscan & NIR provides trust in the product functionality and biological analysis.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss5.png")} alt="" className='bruker-hero-img' />
                </div>
            </div>
            <div className="container azure-container" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{fontSize:'38px'}}><span>ZEISS Elyra 5 with Lattice SIM², PALM & STORM</span></h2>
                        <p>Identifyn™ pushes validation of our primary antibody products and secondary conjugates further by imaging at a resolution of ~60 nm via lattice SIM². Lattice SIM² super resolution provides minimal excitation exposure and high speed and, importantly, provides detail as to specificity that no other validation techniques can provide, as resolutions of the magnitude demonstrate the efficacy of both on-target and off-target binding, ensuring that Identifyn™ products give the research community unmatched confidence in data collection and analysis.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss8.png")} alt="" className='bruker-hero-img' />
                </div>
            </div>
            <div className="container azure-container reverse" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{fontSize:'38px'}}><span>ZEISS ZEN</span></h2>
                        <p>Identifyn™ integrates the power of our Zeiss optical instrumentation with ZEN software, combining image acquisition and robust analysis. ZEN image acquisition software is run independently at each microscope. At the same time, our analysis tools are uniquely housed on Identifyn's Acquifer HIVE system, allowing for access and analysis throughout our lab and remotely anywhere in the world. Zen software is a core tool in Identifyn™ product validation, assay development, and our services to the global scientific industry, offering reliable and reproducible results, powerful visualization, and highly diverse and potent analysis.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/zeiss/zeiss7.png")} alt="" className='bruker-hero-img'  />
                </div>
            </div>
            <Footer />
        </Layout >
    )
}

export default Index

