export const path = {
    '/': {
        title: 'Home',
    },
    '/contact': {
        title: 'Contact Us',
    },
    '/about': {
        title: 'About Us',
        description: 'Identifyn™️ is the first commercial super resolution Imaging research and reagent manufacturing facility. Identifyn™ focuses on bringing fundamental biological pathway research via highly vetted de novo antibodies and Identifyn primary and secondary super resolution vetted flourescent products in support of academic and pharmaceutical research.'
    },
    '/gallery': {
        title: 'Gallery',
    },
    '/terms-and-conditions-of-use': {
        title: 'Terms and Conditions of Use',
        robots: 'noindex,nofollow',
    },
    '/terms-and-conditions-of-sale': {
        title: 'Terms and Conditions of Sale',
        robots: 'noindex,nofollow',
    },
    '/privacy-policy': {
        title: 'Privacy Policy',
        robots: 'noindex,nofollow',
    },
    '/cookie-policy': {
        title: 'Cookie Policy',
        robots: 'noindex,nofollow',
    }, 
    '/intellectual-property': {
        title: 'Intellectual Property',
        robots: 'noindex,nofollow',
    },
    '/b2b-opportunities': {
        title: 'B2B Opportunities',
    },
    '/storm-calibration': {
        title: 'STORM Calibration',
    },
    '/microscopy-methods': {
        title: 'Microscopy Methods',
    },
    '/protein-chemistry-methods': {
        title: 'Protein Chemistry Methods',
    },
    '/team': {
        title: 'Team | Leadership | Advisors',
    },
    '/technologies/azure': {
        title: 'Azure Biosystems',
        canonical: 'https://www.identifyn.com/technologies/azure',
        description: 'Identifyn™ currently deploys two custom-designed Azure Biosystems Sapphire FL Biomolecular Imaging Platforms.'
    },
    '/technologies/acquifer': {
        title: 'Acquifer',
        description: 'Identifyn™ currently empowers data sharing throughout the global scientific community by deploying ACQUFER HIVE technology'
    },
    '/technologies/zeiss': {
        title: 'Zeiss',
        description: 'Identifyn™ has chosen to deploy the power and breadth of optical instrumentation and application via our strategic alignment with Zeiss.'
    },
    '/technologies/bruker': {
        title: 'Bruker',
        description: 'Identifyn™ deploys the power of the Vutara VXL, initially designed and commercialized by our chief scientific officer, Dr. Brian T. Bennett*, the VXL Super Resolution Microscope (SRM) empowers Single-Molecule Localization Microscopy (SMLM) with resolutions to approximately 20 nm in PhotoActivated Localization Microscopy (PALM), Stochastic Optical Reconstruction Microscopy (STORM) and Point Accumulation for Imaging in Nanoscale Topography (PAINT).'
    },
    '/technologies/assay-development': {
        title: 'Identifyn™ Assay Development',
        description: 'Identifyn™ possesses rich experience in all aspects of assay development in Cell-based ELISA, Multiplex Immunofluorescence, and Multi-label In-Cell Western, with core expertise in Immunocytochemistry deploying Super Resolution Microscopy at Single Molecule Resolution.'
    },
    '/services': {
        title: 'Identifyn™ Services',
        description: 'Identifyn™ Collaborates on Multimodal Applications to Realize your Research Vision, Encompassing Experience, Application and the most Powerful Set of Tools in Scientific Industry. Identifyn\'s™ Proprietary Bench to Body™ Methodology connects bench research to clinical applications, supporting predictive pharmaceutical discoveries with a focus on oncology and other disease-related pathways'
    },
    '/technologies/alexaflour-conjugates': {
        title: 'Identifyn™ SRM Alexa Fluor™ Conjugates',
    },
    '/technologies/primary-antibodies': {
        title: 'Identifyn™ De Novo Primary Antibodies',
    },
    '/product/pathways': {
        title: 'Identifyn™ SRM Vetted De Novo Primary Antibodies',
        description: 'Pathways for Identifyn™ SRM Vetted De Novo Primary Antibodies.',
    },
    '/product/alexa-fluor-secondary': {
        title: 'Identifyn™ SRM Alexa Fluor™ Secondary Fluorescent Antibodies',  
    },
    '/product/pathways/ssb': {
        title: 'Identifyn™ Primary Antibodies - Single Stand Break',
    },
    '/product/pathways/dsb-hr': {
        title: 'Identifyn™ Primary Antibodies - Double Stand Break - Homologous Recombination',
    },
    '/product/pathways/dsb-nh': {
        title: 'Identifyn™ Primary Antibodies - Double stand Break - Non Homologous End Joining',
    },
    '/assay-development': {
        title: 'Identifyn™ Assay Development',
        description: 'Let Identifyn™ Deploy the most Powerful Set of Tools in Scientific Industry combined with our Experience in Application, Data Analysis and Interpretation with our Proprietary Bench to Body™ Methodology in Realizing your Assay'
    },
}
